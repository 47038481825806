// Wrappers

.wrapper
	position: relative
	max-width: 90%
	height: 100%
	margin: 0 auto

	&--small
		@extend .wrapper
		width: 980px

	&--large
		@extend .wrapper
		width: 1170px

