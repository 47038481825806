//	Headings

.gigantic, .huge, .large, .bigger, .big,
h1, h2, h3, h4, h5, h6
	color: #222
	font-weight: bold

.gigantic
	font-size: 110px
	line-height: 1.09
	letter-spacing: -2px

.huge, h1
	font-size: 68px
	line-height: 1.05
	letter-spacing: -1px

.large, h2
	font-size: 42px
	line-height: 1.14

.bigger, h3
	font-size: 26px
	line-height: 1.38

.big, h4
	font-size: 22px
	line-height: 1.38

.small, small
	font-size: 10px
	line-height: 1.2


// Basic Text Style

p
	margin: 0 0 20px 0

em
	font-style: italic

strong
	font-weight: bold

hr
	border: solid #ddd
	border-width: 1px 0 0
	clear: both
	margin: 10px 0 30px
	height: 0

blockquote
	border-left: solid 5px
	padding-left: 1em