.content
  padding-top: 120px
  margin: 0 5%


  &-excerpt
    text-align: center

  &-flex
    margin-top: 2rem
    display: flex
    align-items: stretch
    justify-content: space-between


    .content-thumbnail
      position: relative
      width: 40%
      height: inherit

      .blur, .image
        background-position: center
        background-repeat: no-repeat
        background-size: cover
        height: 100%

      .blur
        filter: blur(8px)

      .image
        position: absolute
        top: 0
        width: 100%
        background-size: contain


    .content-html
      width: 60%
      margin-left: 2rem


@media (max-width: 1280px)
  .content
    &-flex
      flex-direction: column

    .content-thumbnail
      width: 100%
      height: 300px

      .blur, .image
        background-position: center
        background-repeat: no-repeat
        background-size: cover
        height: 100%

      .blur
        filter: blur(8px)

      .image
        position: absolute
        top: 0
        width: 100%
        background-size: contain

    .content-html
      width: 100%
      margin-left: 0
      margin-top: 2rem

