.no-margin
	margin: 0

.no-padding
	padding: 0

a
	display: inline-block
	color: $c-highlight
	text-decoration: none

	
input,
textarea
	outline: none
	
.bold
	font-weight: 700
	
.italic
	font-style: italic
	