.blurb

	&--framed
		padding: 15px 0
		background-color: $bg-secondary

	&__heading
		margin: 0 0 15px 0
		color: $c-primary
		font-size: 58px
		font-weight: 800
		
	&__copy
		font-weight: 700
		
		&--tight
			max-width: 300px
			