@keyframes fadeInOut
	0%
		opacity: 1
	50%
		opacity: .5
	100%
		opacity: 1

@keyframes scrollDown
	0%
		transform: translate(-12px, 0px)
		opacity: 0
	75%
		transform: translate(-12px, 45px)
		opacity: 1
	100%
		transform: translate(-12px, 45px)
		opacity: 0
		
@keyframes scaleUp
	0%
		transform: scale(.95)
	100%
		transform: scale(1)
		