.splashscreen
	position: fixed
	top: 0
	left: 0
	display: flex
	width: 100%
	height: 100%
	align-items: center
	justify-content: center
	background-color: $white
	z-index: 100
	transition: opacity .4s .4s
	
	&--is-hidden
		opacity: 0
		
		.preloader
			opacity: 0
		
.preloader
	position: relative
	width: 200px
	height: 200px
	transition: opacity .4s
	
	&::before
		content: ""
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		border: 10px solid $c-highlight
		animation: fadeInOut 1.6s linear infinite

	&__text
		position: absolute
		top: 50%
		right: 0
		transform: translateY(-50%)
		padding: 10px 0
		color: $c-primary
		font-size: 32px
		font-weight: 800
		background-color: $white
		