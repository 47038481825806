.project
  padding-top: 120px
  margin: 0 5%

  &-excerpt
    text-align: center

  &-flex
    margin-top: 2rem
    display: flex
    align-items: stretch
    justify-content: space-between
    flex-direction: column

  .project-thumbnail
    position: relative
    width: 100%
    height: 300px

    .blur, .image
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      height: 100%

    .blur
      filter: blur(8px)

    .image
      position: absolute
      top: 0
      width: 100%
      background-size: contain

  .dates
    margin-top: 2rem
    text-align: center

  .project-html

    margin-left: 0
    margin-top: 1rem
    display: flex
    align-items: center
    flex-direction: column
    h3
      text-align: center
    .details
      margin: auto
      max-width: 80%
    .link
      margin-top: 2rem
      text-align: center


  .gallery
    display: flex
    align-items: center
    justify-content: space-evenly
    height: 600px
    > div
      background-size: contain
      background-position: center
      background-repeat: no-repeat
      transition: all ease-in-out 200ms

      width: 30%
      height: 50%

      &:hover
        width: 66%
        height: 100%
        box-shadow: 5px 8px 30px 1px rgba(0, 0, 0, 0.21)
        cursor: zoom-in

